import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import diyanet1 from "../../assets/img/diyanet1.png"
import diyanet2 from "../../assets/img/diyanet2.png"
import diyanet3 from "../../assets/img/diyanet3.png"
import diyanet4 from "../../assets/img/diyanet4.png"
import diyanet5 from "../../assets/img/diyanet5.png"
import diyanet6 from "../../assets/img/diyanet6.png"

export default function DiyanetProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="T.C. Diyanet İşleri Başkanlığı Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={diyanet1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("diyanet")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu-kurumu")}
            <br />
            <span>{t("project-subject")}</span>: {t("vip-takvim")} - 2011
            <br />
            <span>{t("project-artworkTR")}</span> {t("yedi-adet-hatTR")}
          </div>
          <img src={diyanet2} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={diyanet3} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("diyanet")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu-kurumu")}
            <br />
            <span>{t("project-subject")}</span>: {t("kutlu-dogum-logo")}
            <br />
            <span>{t("project-artwork")}</span>: {t("elif-ve-mim")}
          </div>
          <img src={diyanet4} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={diyanet5} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("diyanet")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu-kurumu")}
            <br />
            <span>{t("project-subject")}</span>: {t("kutlu-dogum-logo")}
            <br />
            <span>{t("project-artwork")}</span>: {t("elif-ve-mim")}
          </div>
          <img src={diyanet6} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
